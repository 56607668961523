.section {
  padding: 10px 40px;
}

.section p {
  white-space: pre-line;
}

.section ul {
  padding: 0;
  list-style-type: none;
}

svg, h2 {
  display: inline;
}

h2, p {
  padding-left: 10px;
}

svg {
  width: 20px;
  margin-left: -20px;
}

